<template>
  <div id="wagon_order_preview_modal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-xl">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Wagon Order Preview
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <section class="row p-4 pt-3" v-if="Object.keys(data).length > 0">
          <div class="col-12 col-lg-6">
            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                <tr class="align-middle">
                  <th scope="row" style="width: 200px">
                    Order Number
                  </th>
                  <td>{{ data.order.order_number }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Lot Number</th>
                  <td>{{ data.order.lot_number }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Position</th>
                  <td>{{ data.order.position }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Type</th>
                  <td>{{ data.order.type }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Shipment Status</th>
                  <td>{{ data.order.shipment_status }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Payment Status</th>
                  <td>{{ data.order.payment_status }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Border Crossing</th>
                  <td>{{ data.order.border_crossing }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">conditions_of_carriage</th>
                  <td>{{ data.order.conditions_of_carriage }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Rolling Stock</th>
                  <td>{{ data.order.rolling_stock }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Manager</th>
                  <td>
                    <div v-if="data.order.user"
                         class="d-flex justify-content-start align-items-center gap-2">
                      <div class="avatar-xxs">
                        <div
                            class="avatar-title bg-soft-success text-success rounded"
                        >
                          {{ data.order.user.username.charAt(0).toUpperCase() }}
                        </div>
                      </div>
                      <router-link
                          @click="closeModal()"
                          :to="{name: 'user_profile', params: {slug:  data.order.user.slug }}">
                        {{ data.order.user.username }}
                      </router-link>
                    </div>
                    <span v-else>--</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="table-responsive">
              <table class="table mb-0">
                <tbody>
                <tr class="align-middle">
                  <th scope="row">Quantity</th>
                  <td>{{ data.order.quantity }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Shipper</th>
                  <td>{{ data.order.shipper }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Consignee</th>
                  <td>{{ data.order.consignee }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Departure</th>
                  <td>{{ data.order.departure.name + ', ' + data.order.departure.code }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Destination</th>
                  <td>{{ data.order.destination.name + ', ' + data.order.destination.code }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Departure Country</th>
                  <td>{{ data.order.departure_country }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Destination Country</th>
                  <td>{{ data.order.destination_country }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Product</th>
                  <td>{{ data.product.name + ', ' + data.product.hc_code }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Company</th>
                  <td>
                    <div v-if="data.order.company"
                         class="d-flex justify-content-start align-items-center gap-2">
                      <div class="avatar-xxs">
                        <div
                            class="avatar-title bg-soft-success text-success rounded"
                        >
                          {{ data.order.company.name.charAt(0).toUpperCase() }}
                        </div>
                      </div>
                      <router-link
                          @click="closeModal()"
                          :to="{name: 'counterparty_profile', params: {slug:  data.order.company.slug }}">
                        {{ data.order.company.name }}
                      </router-link>
                    </div>
                    <span v-else>--</span>
                  </td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Comment</th>
                  <td>{{ data.order.comment }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OrderPreview",
  props: {
    order: {
      type: Object,
      default: () => null
    },
  },
  computed: {
    data() {
      return this.order || {}
    }
  },
  methods: {
    closeModal() {
      document.querySelector('#wagon_order_preview_modal .btn-close').click()
    }
  }
}
</script>

<style scoped>

</style>